import React, { useEffect, useState } from "react";
import ButtonStatus from "../ButtonStatus";
import ArrowDownRightMd from "../../svg/ArrowDownRightMd";
import ArrowDownDashed from "../../../../assets/floworder-arrow/ArrowDownDashed.svg";
import ArrowDownSuccess from "../../../../assets/floworder-arrow/ArrowDownSuccess.svg";
import ArrowDownSolid from "../../../../assets/floworder-arrow/ArrowDownSolid.svg";
import ArrowDownWarning from "../../../../assets/floworder-arrow/ArrowDownWarning.svg";
import ArrowDownInfo from "../../../../assets/floworder-arrow/ArrowDownInfo.svg";
import ArrowDownRightMdDashed from "../../../../assets/floworder-arrow/ArrowDownRightMdDashed.svg";
import ArrowUpRightDashed from "../../../../assets/floworder-arrow/ArrowUpRightDashed.svg";
import ArrowUpRightSolid from "../../../../assets/floworder-arrow/ArrowUpRightSolid.svg";
import { useOrders } from "../../providers/OrdersContext";
import OrderCheckDialog from "../../../../components/OrderCheckDialog/OrderCheckDialog";
import { smCommerceManagementApi } from "../../../../infrastructure/api/SMCommerceManagement";
import { i18n } from "../../../../translate/i18n";
import NewToastComponent from "../../../../components/NewToastComponent";
export default function CreateSeparateStatus({
	indexColumn,
	getTypeIcons,
	getTitleButton,
	getTypeInfo,
	getButtonStyle,
	getTypeArrow,
	getProcessOrder,
	getRetryProcessOrder,
	getFailedProcessOrder,
}) {
	const [separate, setSeparate] = useState([]);
	const [isOrderCheckDialogOpen, setIsOrderCheckDialogOpen] = useState(false);
	const [isLoadingPickingSubmit, setIsLoadingPickingSubmit] = useState(false);

	const {
		orderModel,
		orderId,
		handleChangeStatus,
		flowStatus,
		loadingStatus,
		loadingCancel,
		isCancellation,
	} = useOrders();

	const position = flowStatus?.enqueueOrderPosition;
	const lastPosition = parseInt(flowStatus?.lastOrderPosition);
	const lastProcessedStatus = lastPosition + 1;
	const lastDateUpdate = flowStatus?.actions[2]?.LastUpdate;
	const stepEmpty = position < 0;
	const stepEmptyCancel = position > 100 && lastPosition < 2;
	const stepCancellation = position > 100 && lastPosition === 2;
	const nextStep = flowStatus?.hasNextActionAvailable && position === 2;
	const loading = loadingStatus && position === 2 && !loadingCancel;
	const loadingRetry =
		loadingStatus && !isCancellation && lastPosition === 1 && position === 100;
	const errorCancel =
		position === 100 &&
		lastPosition === 1 &&
		(flowStatus.status === "WaitingCancel" || flowStatus.status === "Canceled");
	const isCanceled =
		position === 100 &&
		(flowStatus.status === "WaitingCancel" || flowStatus.status === "Canceled");
	const enableOrderPicking = JSON.parse(
		localStorage.getItem("configurations")
	)[0]?.enableOrderPicking;

	const getStepCompleted = (position, lastPosition, lastProcessedStatus) => {
		if (position > 2 && position < 100) return true;
		else if (position > 100 && lastPosition > 2) return true;
		else if (position === 100 && lastProcessedStatus > 2) return true;
		else return false;
	};

	const stepCompleted = getStepCompleted(
		position,
		lastPosition,
		lastProcessedStatus
	);

	const processing = getProcessOrder(position === 2);

	const processingRetry = getRetryProcessOrder(position === 2);

	const failedProcess = getFailedProcessOrder(
		position === 2,
		lastProcessedStatus === 2
	);
	useEffect(() => {
		if (stepCancellation || errorCancel) {
			setSeparate([0]);
		} else {
			setSeparate([0, 1]);
		}
	}, [stepCancellation, errorCancel]);

	const handleIcons = (index) => {
		switch (index) {
			case 0:
				return getTypeIcons("AllInbox");
			case 1:
				if (processing || processingRetry || loading)
					return getTypeIcons("WatchLater");
				else if (failedProcess) return getTypeIcons("Refresh");
				else return getTypeIcons("CheckCircle");
			default:
				break;
		}
	};

	const handleTitles = (index) => {
		switch (index) {
			case 0:
				return getTitleButton("WaitingSeparationPending");
			case 1:
				if (loading) return getTitleButton("Processing");
				else if (failedProcess) return getTitleButton("Reprocess");
				else if (stepCompleted)
					return getTitleButton("WaitingSeparationCompleted");
				else return getTitleButton("WaitingSeparationAction");
			default:
				break;
		}
	};

	const handleInfo = (index) => {
		const empty = stepEmpty || stepEmptyCancel;
		const process = processing || processingRetry;

		if (empty) return getTypeInfo("NotStarted");
		else if (stepCompleted) return getTypeInfo(lastDateUpdate);
		else return showCorrectInfo(index, process);
	};

	const showCorrectInfo = (index, process) => {
		const load = loadingRetry && !errorCancel;
		switch (index) {
			case 0:
				if (nextStep || process || load) return flowStatus?.integrator;
				else if (stepCancellation || errorCancel)
					return getTypeInfo("InCancellation");
				else if (failedProcess) return getTypeInfo("IntegrationFailure");
				else return getTypeInfo("NotStarted");
			case 1:
				if (loading) return getTypeInfo("WaitAMinute");
				else if (nextStep || errorCancel) return getTypeInfo("ClickToConfirm");
				else if (processingRetry || loadingRetry)
					return getTypeInfo("ProcessingRetry");
				else if (failedProcess) return getTypeInfo("TryAgain");
				else if (processing) return getTypeInfo("Processing");
				else return getTypeInfo("NotStarted");
			default:
				break;
		}
	};

	const handleStyles = (index) => {
		const empty = stepEmpty || stepEmptyCancel;
		const process = processing || processingRetry;

		if (empty) return getButtonStyle("Empty");
		else if (stepCompleted) return getButtonStyle("Completed");
		else return showCorrectStyle(index, process);
	};

	const showCorrectStyle = (index, process) => {
		const load = loadingRetry && !errorCancel;
		switch (index) {
			case 0:
				if (process || loading || load) return getButtonStyle("SuccessOutline");
				else if (stepCancellation || errorCancel)
					return getButtonStyle("Canceled");
				else if (failedProcess || nextStep)
					return getButtonStyle("WarningOutline");
				else return getButtonStyle("Empty");
			case 1:
				if (process || loading || loadingRetry)
					return getButtonStyle("RetryOutline");
				else if (nextStep || errorCancel) return getButtonStyle("Success");
				else if (failedProcess) return getButtonStyle("Warning");
				else return getButtonStyle("Empty");
			default:
				break;
		}
	};

	const handleIntegration = (index) => {
		const failed = "failed";
		const retry = "retry";
		const process = "process";
		const empty = "empty";
		switch (index) {
			case 0:
				if (loading || loadingRetry || errorCancel) return empty;
				else if (failedProcess) return failed;
				else return empty;
			case 1:
				if (processing || loading || loadingRetry) return process;
				else if (nextStep) return empty;
				else if (processingRetry) return retry;
				else return empty;
			default:
				break;
		}
	};

	const handleDisabledButton = (index) => {
		const clickConfirm = nextStep || failedProcess;
		if (loadingStatus || loadingCancel) return true;
		else {
			if (index === 0) return true;
			if (index === 1) return clickConfirm ? false : true;
		}
	};

	const handleArrowStepCancellation = (index) => {
		if (index === 0)
			return (
				<ArrowDownRightMd currentStep={getTypeArrow("Solid")} left={"85px"} />
			);
	};

	const handleArrowStepMain = (index) => {
		const process = processing || processingRetry;
		const arrow = position < 3 || (lastPosition <= 1 && position === 100);
		switch (index) {
			case 0:
				if (process || loading || loadingRetry)
					return getTypeArrow(ArrowDownInfo, "arrow-down");
				else if (nextStep || errorCancel)
					return getTypeArrow(ArrowDownSuccess, "arrow-down");
				else if (failedProcess)
					return getTypeArrow(ArrowDownWarning, "arrow-down");
				else if (stepCompleted)
					return getTypeArrow(ArrowDownSolid, "arrow-down");
				else return getTypeArrow(ArrowDownDashed, "arrow-down");
			case 1:
				if (stepCompleted)
					return getTypeArrow(ArrowUpRightSolid, "arrow-up-right");
				else if (isCanceled)
					return getTypeArrow(ArrowUpRightDashed, "arrow-up-right");
				else
					return (
						<>
							{getTypeArrow(ArrowUpRightDashed, "arrow-up-right")}
							{arrow &&
								getTypeArrow(ArrowDownRightMdDashed, "arrow-down-right-md")}
						</>
					);
			default:
				break;
		}
	};

	const createStatusSeparate = () => {
		const cancellation = stepCancellation || errorCancel;
		return separate.map((index) => {
			let modalName;
			if (index === 2 && indexColumn === 3) modalName = "#orderFlowCancel";
			else if (failedProcess) modalName = "";
			else modalName = enableOrderPicking ? "#" : "#orderFlow";
			return (
				<>
					<ButtonStatus
						key={index}
						name={modalName}
						icons={handleIcons(index)}
						title={handleTitles(index)}
						info={handleInfo(index)}
						style={handleStyles(index)}
						integration={handleIntegration(index)}
						disabled={handleDisabledButton(index)}
						position={position}
						isError={failedProcess}
						{...(enableOrderPicking && {
							setOpenModal: setIsOrderCheckDialogOpen,
						})}
					/>
					{cancellation
						? handleArrowStepCancellation(index)
						: handleArrowStepMain(index)}
				</>
			);
		});
	};

	const onSubmitPicking = async (pickingData) => {
		setIsLoadingPickingSubmit(true);
		try {
			const success = await smCommerceManagementApi.upsertPicking(
				orderId,
				pickingData
			);
			if (!success) {
				NewToastComponent({
					status: "error",
					title: i18n.t("errorMessages.Default"),
					message: i18n.t("errorMessages.TryAgainLater"),
				});
				return;
			}
			const changeStatusSuccess = await handleChangeStatus(position);
			if (changeStatusSuccess) setIsOrderCheckDialogOpen(false);
		} catch {
			NewToastComponent({
				status: "error",
				title: i18n.t("errorMessages.Default"),
				message: i18n.t("errorMessages.TryAgainLater"),
			});
		}
		setIsLoadingPickingSubmit(false);
	};

	return (
		<>
			{createStatusSeparate()}
			<OrderCheckDialog
				orderModel={orderModel}
				isOrderCheckDialogOpen={isOrderCheckDialogOpen}
				setIsOrderCheckDialogOpen={setIsOrderCheckDialogOpen}
				isLoadingSubmit={isLoadingPickingSubmit}
				onSubmitCallback={onSubmitPicking}
				onCancelCallback={() => {
					setIsOrderCheckDialogOpen(false);
					const cancelOrderBtn = document.createElement("button");
					cancelOrderBtn.type = "button";
					cancelOrderBtn.style.display = "none";
					cancelOrderBtn.setAttribute("data-toggle", "modal");
					cancelOrderBtn.setAttribute("data-target", "#orderFlowCancel");
					document.body.appendChild(cancelOrderBtn);
					cancelOrderBtn.click();
					document.body.removeChild(cancelOrderBtn);
				}}
			/>
		</>
	);
}
