import React, {
	ChangeEvent,
	KeyboardEvent,
	ReactNode,
	useEffect,
	useRef,
	useState,
} from "react";
import { ErrorOutline } from "@mui/icons-material";
import {
	FormControl,
	FormHelperText,
	InputAdornment,
	InputLabel,
	OutlinedInput,
} from "@mui/material";
import { i18n } from "../translate/i18n";
import { applyMask } from "../infrastructure/utils/FormatUtils";
import { normalizeDigitsOnly } from "../infrastructure/utils/StringUtils";
import HelpTooltip, { HelpTooltipConfig } from "./HelpTooltip";

type startAdornmentOptions = "currency" | undefined;

type InputProps = {
	title?: string;
	name: string;
	value: any;
	placeholder?: string;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	onKeyDown?: (
		event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void;
	error?: boolean;
	helperText?: string[];
	type?: string;
	endAdornment?: ReactNode;
	multiline?: boolean;
	rows?: number;
	applyStartAdornment?: startAdornmentOptions;
	startAdornmentElement?: JSX.Element;
	disabled?: boolean;
	maxChar?: number;
	customWidth?: string;
	applyInputMask?: string;
	helpTooltip?: HelpTooltipConfig;
};

export default function CustomInput({
	title,
	name,
	value,
	placeholder = title,
	onChange,
	onKeyDown,
	error = false,
	helperText = [],
	type = "text",
	endAdornment = null,
	rows = 0,
	multiline = false,
	applyStartAdornment = undefined,
	startAdornmentElement = undefined,
	disabled = false,
	maxChar,
	customWidth,
	applyInputMask,
	helpTooltip,
}: InputProps) {
	const [maskedInput, setMaskedInput] = useState<string>("");
	const alreadyRenderedMaskValue = useRef<boolean>(false);

	useEffect(() => {
		if (applyInputMask && value && !alreadyRenderedMaskValue.current) {
			setMaskedInput(applyMask(value, applyInputMask));
			alreadyRenderedMaskValue.current = true;
		}
	}, [value]);

	const renderStartAdornment = (
		startAdornment: startAdornmentOptions
	): JSX.Element | undefined => {
		if (startAdornment === "currency") {
			return (
				<InputAdornment position="start">
					{i18n.t("input.CurrencyAdornment").toString()}
				</InputAdornment>
			);
		}
		return undefined;
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (maxChar) {
			e.target.value = e.target.value.slice(0, maxChar);
		}
		if (applyInputMask) {
			const value = normalizeDigitsOnly(e.target.value);
			if (value.length > normalizeDigitsOnly(applyInputMask).length) return;
			setMaskedInput(applyMask(value, applyInputMask));
			e.target.value = value;
		}
		onChange(e);
	};

	return (
		<FormControl
			size="small"
			fullWidth
			disabled={disabled}
			sx={{ ...(customWidth && { width: customWidth }) }}
		>
			{title && (
				<InputLabel color="secondary" error={error}>
					{title}
					{helpTooltip && <HelpTooltip helpTooltip={helpTooltip} />}
				</InputLabel>
			)}
			<OutlinedInput
				autoComplete="new-password"
				error={error}
				label={title}
				placeholder={placeholder}
				id={name}
				name={name}
				color="secondary"
				onChange={handleChange}
				value={(applyInputMask ? maskedInput : value) ?? ""}
				type={type}
				rows={rows}
				multiline={multiline}
				endAdornment={
					endAdornment ? (
						<>
							{endAdornment}
							{error && (
								<ErrorOutline sx={{ marginLeft: "8px" }} color="error" />
							)}
						</>
					) : (
						error && <ErrorOutline color="error" />
					)
				}
				{...(onKeyDown && { onKeyDown: onKeyDown })}
				{...(applyStartAdornment !== undefined && {
					startAdornment: renderStartAdornment(applyStartAdornment),
				})}
				{...(startAdornmentElement !== undefined && {
					startAdornment: startAdornmentElement,
				})}
			/>
			{helperText.length > 0 && (
				<FormHelperText error={error}>{helperText}</FormHelperText>
			)}
		</FormControl>
	);
}
